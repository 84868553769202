import { useNavigate } from '@remix-run/react'
import {
	type InternalRoutes,
	getLinkByRoute,
} from '#app/components/locale-link.tsx'
import { useCurrentLanguage } from './use-current-language.tsx'

interface NavigateProps {
	route: InternalRoutes
	childRoute?: InternalRoutes
	params?: Object
	searchParams?: string
}
export const useCustomNavigate = () => {
	const navigate = useNavigate()
	const currentLanguage = useCurrentLanguage()

	return ({ route, params, searchParams, childRoute }: NavigateProps) => {
		navigate(
			getLinkByRoute({
				linkRouteId: route,
				locale: currentLanguage,
				params,
				searchParams,
				isChildOf: childRoute,
			}),
		)
	}
}
